import { Role } from 'src/types/common'

export const TRAINING_CONTENT_STATUS_LIST = [
  {
    name: '公開',
    value: 'published',
  },
  {
    name: 'ドラフト',
    value: 'draft',
  },
]

export const ROLES = {
  employee: 1,
  back_office: 2,
  manager: 3,
  general_manager: 4,
}

export const ROLES_TEXT = {
  employee: 'employee',
  backOffice: 'back_office',
  manager: 'manager',
  generalManager: 'general_manager',
}

export const STAGE_TYPES = {
  NONE: 'none',
  DX: 'dx',
  LITERACY: 'literacy',
}

export const USER_HOME_PAGE_LINK = '/user/home'
export const STARTUP_USER_PAGE_LINK = '/user/training_content_categories'
export const STARTUP_MANAGEMENT_PAGE_LINK: {
  [K in Role]?: string
} = {
  back_office: '/management/tms/departments',
  manager: '/management/tms/departments',
  general_manager: '/management/tms/departments',
}

export const ASSESSMENT_LITERACY_PATHNAMES = [
  `/user/assessments/start`,
  `/user/assessments/literacy`,
  `/user/assessments/literacy/result`,
]

export const COMMON_DEFAULT_PER = 10

export const POINT_TO_PASS_LECTURE = 80

export const DEFAULT_PER_ALL_OPTIONS = 1000

export const UPDATE_PASSWORD_PATH = '/user/update_password'

export const MANAGEMENT_UPDATE_PASSWORD_PATH = '/management/update_password'

export const DEFAULT_RANK = '-'
export const RANK_A_ID = 'A'
export const RANK_B_ID = 'B'
export const RANK_C_ID = 'C'

export const SKILL_RANKS = {
  [DEFAULT_RANK]: {
    backgroundColor: 'rank.none',
    label: '-',
  },
  [RANK_A_ID]: {
    backgroundColor: 'rank.a',
    label: 'A',
  },
  [RANK_B_ID]: {
    backgroundColor: 'rank.b',
    label: 'B',
  },
  [RANK_C_ID]: {
    backgroundColor: 'rank.c',
    label: 'C',
  },
}

export const NOT_FOUND_INDEX = -1
export const DASH_OPTION_ID = -1

export const REPORT_CONTENTS = [
  {
    id: 1,
    name: '正解の選択肢がおかしい',
  },
  {
    id: 2,
    name: '問題文に誤りがある',
  },
  {
    id: 3,
    name: '解説文に誤りがある',
  },
  {
    id: 4,
    name: 'その他',
  },
]

export const STUDY_STATUS_TYPE = {
  dxLiteracy: 'DXリテラシー',
  dxSkill: 'DX推進スキル',
  itPassport: 'ITパスポート',
}

export const FIRST_PAGE = 1

export const DUMMY_COLOR_LINE_CHART = [
  {
    linearColorFirst: '#00BCD4',
    linearColorEnd: '#00BCD400',
    dominantColor: '#57C5F0',
  },
  {
    linearColorFirst: '#6DB3814D',
    linearColorEnd: '#6DB38100',
    dominantColor: '#6DB381',
  },
  {
    linearColorFirst: '#ECB22D4D',
    linearColorEnd: '#ECB22D00',
    dominantColor: '#ECB22D',
  },
  {
    linearColorFirst: '#E01E5A4D',
    linearColorEnd: '#E01E5A00',
    dominantColor: '#E01E5A',
  },
]

export const LITERACY_CHART_FILTER = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  yearly: 'yearly',
}

export const PROMOTION_SKILL_CHART_FILTER = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  yearly: 'yearly',
}

export const MIN_RADAR_CHART_DATA = 5
export const MAX_RADAR_CHART_DATA = 50
export const DX_SKILL_GROUP_NAME = 'DX推進スキル'

export const AUTHORITY_TITLE = 'プラン制限'

export const AUTHORITY_LIMIT = {
  department: 1,
  departmentGroup: 1,
  user: 10,
}

export const AUTHORITY_MESSAGES = {
  limitDepartmentGroupEqual: '現在のプランでは、事業部をひとつまでしか登録できません',
  limitDepartmentGroupGreater: '現在のプランでは、これ以上事業部を登録できません',
  limitDepartmentEqual: '現在のプランでは、部署をひとつまでしか登録できません',
  limitDepartmentGreater: '現在のプランでは、これ以上部署を登録できません',
  limitUserEqual: '現在のプランでは、従業員を10人までしか登録できません',
  limitUserGreater: '現在のプランでは、これ以上従業員を登録できません',
  visualizeSkill: 'このサービスは有料プランでのみご利用いただけます',
  withoutGeneralManager: '権限がありません。',
}

export const PRIVILEGE_TYPES = {
  registerDepartmentNoLimit: 'register_department_no_limit',
  registerUserNoLimit: 'register_user_no_limit',
  visualizeSkill: 'visualize_skill',
  viewLiteracyVideo: 'view_literacy_video',
  viewDxSkillVideo: 'view_dx_skill_video',
  viewLiteracyVideoLimited: 'view_literacy_video_limited',
  viewDxSkillVideoLimited: 'view_dx_skill_video_limited',
}

export const BILLING_PLAN_ICONS = {
  arrowUp: 'red-up-arrow',
}

export const TRIAL_DATE_CLOSE_TO_FINISHED = 3

export const BILLING_PLAN_PERIODS = {
  monthly: 'monthly',
  yearly: 'yearly',
}

export const BILLING_PLAN_IDS = {
  trial: 1,
  free: 2,
  paid: 3,
}

export const PROCESSING_NEW = '新規'
export const PROCESSING_RE_NEW = '更新'

export const ROLE_LIMIT_TITLE = 'ロール制限'

export const SECOND_PER_QUESTION = 80

export const FIRST_SCORE_RETRIEVAL_RATE_THRESHOLD = 0.9

export const DX_LITERACY_SKILL_GROUP_DATA = [
  {
    id: 1,
    name: 'Why',
    color: '#52B67C',
    image: '/skill_category_group_thumbnail/thumbnail1.svg',
    category: 'DXの背景',
    description: 'DXの重要性を理解するために必要な、社会、顧客・ユーザー、競争環境の変化に関する知識を定義',
    content: 'DXに関するリテラシーとして身につけるべき知識の学習の指針とする',
  },
  {
    id: 2,
    name: 'What',
    image: '/skill_category_group_thumbnail/thumbnail2.svg',
    color: '#ECB22D',
    category: 'DXで活用するデータ・技術',
    description: 'ビジネスの場で活用されているデータやデジタル技術に関する知識を定義',
    content: 'DXに関するリテラシーとして身につけるべき知識の学習の 指針とする',
  },
  {
    id: 3,
    name: 'How',
    image: '/skill_category_group_thumbnail/thumbnail3.svg',
    color: '#E01E5A',
    category: 'データ・技術の利活用',
    description: 'ビジネスの場でデータやデジタル技術を利用する方法や、活用事例、留意点に関する知識を定義',
    content: 'DXに関するリテラシーとして身につけるべき知識の学習の指針とする',
  },
  {
    id: 4,
    name: 'マインド・スタンス',
    category: '',
    color: '#57C5F0',
    image: '/skill_category_group_thumbnail/thumbnail4.svg',
    description: '社会変化の中で新たな価値を生み出すために必要な意識・姿勢・行動を定義',
    content:
      '個人が自身の行動を振り返るための指針かつ、組織・企業がDX推進や持続的成長を実現するために、構成員に求める意識・姿勢・行動を検討する指針とする',
  },
]
export const DX_SKILL_SKILL_GROUP_DATA = [
  {
    id: 1,
    name: 'Biz',
    color: '',
    image: '/skill_category_group_thumbnail/thumbnail5.svg',
    category: 'ビジネスアーキテクト',
    description:
      'ビジネスや業務の変革を通じて実現したいこと（＝目的）を設定したうえで、関係者間の協働を仰ぎ、目的実現に向けた推進をする',
    content: '',
    roles: [
      {
        name: '新規事業開発',
        description:
          '新しい事業、製品・サービスの目的を見出し、新しく定義した目的の実現方法を策定したうえで、関係者間の協働を仰ぎ、推進をする',
      },
      {
        name: '既存事業の高度化',
        description:
          '既存の事業、製品・サービスの目的を見直し、再定義した目的の実現方法を策定したうえで、関係者間の協働を仰ぎ、推進をする',
      },
      {
        name: '社内業務の高度化・効率化',
        description: '社内業務の課題解決の目的を定義し、その目的の実現方法を策定したうえで、目的実現に向けた推進をする',
      },
    ],
  },
  {
    id: 2,
    name: 'Des',
    image: '/skill_category_group_thumbnail/thumbnail6.svg',
    color: '',
    category: 'デザイナー',
    description:
      'ビジネス、顧客の視点等を総合的にとらえ、サービスの方針や開発のプロセスを策定し、それらに沿ったデザインを担う',
    content: '',
    roles: [
      {
        name: 'サービスデザイナー',
        description:
          '社会、顧客、社内外関係者の課題や行動から顧客価値を定義しサービスのコンセプトを策定し、それを継続的に実現するための仕組みをデザインする',
      },
      {
        name: 'UX/UIデザイナー',
        description:
          'バリュープロポジション※に基づきサービスの顧客体験を設計し、サービスの情報設計や、機能、情報の配置、外観、動的要素のデザインを行う\n\n※顧客が求める価値を把握した上で、ビジネスのケイパビリティを踏まえて決定される、企業が製品・サービスを購入する顧客に提供する利益や、顧客がその製品・サービスを買うべき理由',
      },
      {
        name: 'グラフィックデザイナー',
        description:
          'ブランドのイメージを具現化し、ブランドとして統一感のあるデジタルグラフィック、マーケティング媒体等のデザインを行う',
      },
    ],
  },
  {
    id: 3,
    name: 'Dsc',
    image: '/skill_category_group_thumbnail/thumbnail7.svg',
    color: '',
    category: 'データサイエンティスト',
    description:
      'データを活用した業務変革や新規事業の実現に向けて、データを収集・解析する仕組みの設計・実装・運用を担う',
    content: '',
    roles: [
      {
        name: 'データビジネスストラテジスト',
        description:
          '事業戦略に沿ったデータの活用戦略を考え、実現を主導し、顧客価値を拡大する業務変革やビジネス創出を実現する',
      },
      {
        name: 'データサイエンスプロフェッショナル',
        description:
          'データの処理や解析を通じて、顧客価値を拡大する業務の変革やビジネスの創出につながる有意義な知見を導出する',
      },
      {
        name: 'データエンジニア',
        description:
          '効果的なデータ分析環境の設計・実装・運用を通じて、顧客価値を拡大する業務変革やビジネス創出を実現する',
      },
    ],
  },
  {
    id: 4,
    name: 'Eng',
    category: 'ソフトウェアエンジニア',
    color: '',
    image: '/skill_category_group_thumbnail/thumbnail8.svg',
    description: 'デジタル技術を活用した製品・サービスを提供するためのシステムやソフトウェアの設計・実装・運用を担う',
    content: '',
    roles: [
      {
        name: 'フロントエンドエンジニア',
        description:
          'ソフトウェアの機能のうち、主にインターフェース（クライアントサイド）の機能の実現に主たる責任を持つ',
      },
      {
        name: 'バックエンドエンジニア',
        description: 'ソフトウェアの機能のうち、主にサーバサイドの機能の実現に主たる責任を持つ',
      },
      {
        name: 'クラウドエンジニア/SRE',
        description: 'ソフトウェアの開発・運用環境の最適化と信頼性の向上に責任を持つ',
      },
      {
        name: 'フィジカルコンピューティングエンジニア',
        description:
          'ソフトウェアの実現において、現実世界（物理領域）のデジタル化を担い、デバイスを含めたソフトウェア機能の実現に責任を持つ',
      },
    ],
  },
  {
    id: 5,
    name: 'Sec',
    category: 'サイバーセキュリティ',
    color: '',
    image: '/skill_category_group_thumbnail/thumbnail9.svg',
    description: 'サイバーセキュリティリスクの影響を抑制する対策を担う',
    content: '',
    roles: [
      {
        name: 'サイバーセキュリティマネージャー',
        description:
          'セキュリティリスクを検討・評価し、対策の管理・統制の主導を通じて、顧客価値の高いビジネスへの信頼向上に貢献する',
      },
      {
        name: 'サイバーセキュリティエンジニア',
        description:
          'セキュリティリスクを抑制するための対策の導入・保守・運用を通じて、顧客価値の高いビジネスの安定的な提供に貢献する',
      },
    ],
  },
]

export const LOCAL_STORAGE_KEY_NAME = {
  trainingContentCategory: 'trainingContentCategory',
  assessmentResult: 'assessmentResult',
  easyAssessmentResult: 'easyAssessmentResult',
}

export type EXAM_RESULT_VALUE = '' | 'pass' | 'fail' | 'none'
export const EXAM_RESULTS: { label: string; value: EXAM_RESULT_VALUE }[] = [
  {
    label: '合格',
    value: 'pass',
  },
  {
    label: '不合格',
    value: 'fail',
  },
  {
    label: '未受験',
    value: 'none',
  },
]

export const DX_SKILL_CATEGORY_GROUP_ID = {
  dxLiteracy: 1,
  dxSkill: 2,
  itPassport: 3,
}

export const TRAINING_CONTENT_CATEGORY_ID = {
  dxLiteracy: 2,
  dxSkill: 3,
  itPassport: 1,
}

export const DX_SKILL_CATEGORY_GROUP_URL_BASES = {
  1: '/user/training_content_categories/1/training_contents/7',
  2: '/user/training_content_categories/2/training_contents/1',
  3: '/user/training_content_categories/3/training_contents',
}

export const TUTORIAL_IDS = {
  introduction: 2,
  registerDepartment: 3,
  registerUser: 4,
  setTarget: 5,
  closing: 6,
}

export const IMAGE_POSITION = {
  upper: '問題文上部',
  lower: '問題文下部',
}

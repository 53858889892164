import { all, takeLatest, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'
import { userLogin, userLoginSuccess, userGetProfile, userGetProfileSuccess, userLogout } from './actions'
import { login, logout, profile } from 'src/apis/user/auth'

import { AuthInfo, LoginForm, LogoutPayload } from 'src/types/common'
import handleError from 'src/utils/handleError'
import { STARTUP_MANAGEMENT_PAGE_LINK, USER_HOME_PAGE_LINK } from 'src/constants'
import { sendCompanyId } from 'src/utils/gtm'

export const UserAuthKey = 'UserAuthKey'

type LoginAction = {
  type: string
  payload: LoginForm
}

type LogoutAction = {
  type: string
  payload: LogoutPayload
}

function* userLoginSaga(action: LoginAction) {
  try {
    const {
      headers,
      data: { data },
    } = yield* call(login, action.payload)
    yield put(userLoginSuccess(headers as AuthInfo))

    // save into local storage
    localStorage.setItem(
      UserAuthKey,
      JSON.stringify({
        client: headers.client,
        'access-token': headers['access-token'],
        uid: headers.uid,
      })
    )

    switch (action.payload.via) {
      case 'user':
        if (!data.passwordSetupAt) {
          return (location.href = '/user/update_password')
        }

        location.href = USER_HOME_PAGE_LINK
        break
      case 'manager':
        if (data.passwordSetupAt) {
          console.log(data.roleKey)
          location.href = STARTUP_MANAGEMENT_PAGE_LINK[data.roleKey] as string
        } else {
          location.href = '/management/update_password'
        }
        break
      default:
        break
    }
  } catch (error) {
    handleError(error)
  }
}

function* userLogoutSaga(action: LogoutAction) {
  try {
    yield call(logout)

    // remove local storage
    localStorage.removeItem(UserAuthKey)

    switch (action.payload.via) {
      case 'user':
        location.href = '/user/login'
        break
      case 'manager':
        location.href = '/management/login'
        break
      default:
        break
    }
  } catch (error) {
    handleError(error)
  }
}

function* userGetProfileSaga() {
  try {
    const { user } = yield call(profile)
    sendCompanyId(user?.company?.id)
    console.log('企業ID送信', user?.company?.id)
    yield put(userGetProfileSuccess(user))
  } catch (error) {
    handleError(error)
  }
}

function* loginSaga() {
  yield all([
    takeLatest(userLogin.type, userLoginSaga),
    takeLatest(userLogout.type, userLogoutSaga),
    takeLatest(userGetProfile, userGetProfileSaga),
  ])
}

export default loginSaga

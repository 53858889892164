import PropTypes from 'prop-types'
import { toast, TypeOptions } from 'react-toastify'

type Toast = {
  type?: Exclude<TypeOptions, 'default'>
  message: string
  duration?: number
}

toast.success

const ToastMessage = ({ type, message, duration = 2500 }: Toast) => {
  switch (type) {
    case 'error':
      return toast.error(message, { autoClose: duration })
    case 'success':
      return toast.success(message, { autoClose: duration })
    case 'warning':
      return toast.warning(message, { autoClose: duration })
    case 'info':
    default:
      return toast.info(message, { autoClose: duration })
  }
}

ToastMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

ToastMessage.dismiss = toast.dismiss

export default ToastMessage
